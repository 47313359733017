import { Injectable } from "@angular/core";
import { Factory } from 'app/app.factory';
//import { RecordStatus } from 'app/common/enum/type.enum';
import { DatePipe } from '@angular/common';
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { merge, Subscribable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

@Injectable()
export class RecentlyAccessedService {
    constructor(private factory: Factory,private auth: AuthService, private datepipe:DatePipe) {}

    public service: any = this.factory.getService();;
    private userInfor: any = this.auth.getUserInforFn();

    public initTableByHP(
      properties: string[], 
      options: {
          switchMap?: () => void,
          map?: (data: PageResult) => void,
          catchError?: () => void,
          subscribe: (data) => void,
      },
  ): void {
      this.service = this.factory.getService();
      let searchProperties: string = 'properties=' + properties.join('%2C');

      var p = { eid: this.userInfor.EID };

      var result="";
      this.service.GetRecentlyAccessed(p).then(res => {
        if(res.Total>0){
          result = "format=json&q=uri:";
          res.Data.forEach(element => {
            result+=element.RecordUri+","
          });
          result=result.substring(0,result.length-1);
          console.log("zcxzc",result);
          this.bindData(result,searchProperties,options,res.Data);
        }else{
          this.bindData(result,searchProperties,options,res.Data);
        }
        

      }, err => {
        console.log(err);
      });

  }

  
  bindData(searchParams: string,searchProperties: string,options: {
    switchMap?: () => void,
    map?: (data: PageResult) => void,
    catchError?: () => void,
    subscribe: (data) => void,
  },aux: any){
      let toMerge: Subscribable<any>[] = [];
      merge(toMerge).pipe(
          startWith({}),
          switchMap(() => {
              if (options.switchMap) {
                  options.switchMap();
              }
              
              return this.service.HPServiceRecord(searchParams, searchProperties).then(res => {
                let readOnlyKeyCategoryArr = [];
                var readOnlyKeyCategoryString = this.auth.getUserInforFn().KeyCategory;
                var readOnlyKeyCategory = readOnlyKeyCategoryString.split(';');
                res.Results.forEach(val => {
                  if(readOnlyKeyCategory.indexOf(val.Fields.KeyCategory.Value) != -1){
                    readOnlyKeyCategoryArr.push(val);
                  }
                })
                console.log("1232",readOnlyKeyCategoryArr);
                let result = new PageResult();
                result.Total = res.TotalResults;
                if(this.auth.getUserInforFn().Group[1]=="MMRP.ReadOnly"){
                  result.Data = this.sortRecentlyAccessed(readOnlyKeyCategoryArr,aux);
                }else{
                  result.Data = this.sortRecentlyAccessed(res.Results,aux);
                }
                return result; 
              });
          }),
          map((resData: PageResult) => {
              if (options.map) {
                  options.map(resData);
              }
              return resData.Data;
          }),
          catchError(() => {
              if (options.catchError) {
                  options.catchError();
              }
              return observableOf([]);
          }),
      ).subscribe(options.subscribe);      

    }

    sortRecentlyAccessed(hpr: any, aux: any){
      hpr.forEach(hprRow => {
        aux.forEach(auxRow => {
          if(hprRow.Uri==auxRow.RecordUri){
            hprRow.TrimType=auxRow.CreateDate;
            hprRow.Docs = [...auxRow.Docs];
          }
        });
      });
      return hpr.sort((a, b) => b.TrimType.localeCompare(a.TrimType));   
    }


    insertRecentlyAccessed(uri: string){
      var array= [];
      var p = { eid: this.userInfor.EID,uri:uri };
      this.service.InsertRecentlyAccessed(p).then(res => {
        if(res.Total>0){
          console.log("sus");
        }
      }, err => {
        console.log(err);
      }); 
      return array;   
    }


}

export class RecentlyAccessed {
    public Id: number;
    public Eid: string;
    public RecordUri: string;
    public CreateDate: Date;
}
  

  export class PageResult {
    public Total: number;
    public Data: object[];
}