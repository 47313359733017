import { Component, EventEmitter, Output } from '@angular/core';
import { Factory } from '../../../../../app.factory'
import { HPEStaticService } from "../../../../../common/services/staticService/HPEStaticService";
import { configurationIDs } from 'app/common/enum/configurationIDs';
import { MessageService } from 'app/common/services/message/messageService';
import { ViewRecordStaticService } from "../../../../../common/services/staticService/ViewRecordStaticService";
import { RecordType, SupplierSearch } from 'app/common/enum/type.enum';
import { Router, ActivatedRoute } from '@angular/router';
import { FilesUpload } from 'app/common/enum/warning.enum'
import { PageData } from 'app/common/services/staticService/page-navigation.service';
@Component({
    selector: 'BasicInfo',
    templateUrl: './BasicInfo.component.html',
    styleUrls: ['./BasicInfo.component.css'],
    providers: [Factory],
    inputs: ["inputsData", "secondarySuppliersInputer","templatePage","isTemplate"],
    outputs: ['outputRecordTypeData', "sndSuppliersOutputer"]
})
export class BasicInfoComponent {
    public templatePage: boolean;
    public isTemplate: boolean;
    public inputsData: any;
    public service: any;
    public recordFormData: any;
    public searchButtonFlag: any;
    public recordDorpdownData: any;
    public parentDorpdownValues: any;
    public secondarySupplierList: any;
    public secondarySuppliersInputer: any;
    public secondarySupplierDataSource: any =[];
    public scndSupplierList: any = [];
    public sndSuppliersOutputer = new EventEmitter();
    public scndSupllierSearchShowOuter: any;
    public scndSuplierOutputData: any = new SecondarySuppliersOutputData();
    public selectSupplier: any = {
        PrimaryCountry: "", SupplierName: "", CompanyCode: "",
        SupplierUri: "", SupplierNotYetInSystem: false, NoSAPIDrequired: false,
    }
    public keySupplier: any = { selectSupplier: this.selectSupplier, keySupplierList: [] };
    public isSearchSupplierShow: boolean;
    public isMore: boolean = true;
    public isParentSearchShow: boolean;
    public isParentPop: boolean;
    public isAllSupplierChecked: boolean;
    public isScndSupllierTipShow: boolean = false;
    public isSearchScndSupllier: boolean = false;
    public outputRecordTypeData = new EventEmitter();
    public FileListlength: number;
    public isOtherLanguageShow: boolean = false;
    public ParentDocumentAbled: boolean = true;
    public parentRed: any;
    public RecordUri: any;
    public childlist:any = [];
    public OriginalRecordType: any;
    public OriginalMarketIntelligence: any;
    public OriginalInfoSec: any;
    public OriginalGDPR: any;
    public OriginalUSDExceeded: any;
    public OriginalSupplierCodeOfConduct: any;
    public OriginalAgreementSpentType: any;
    public OriginalLegacyTool: any;
    public OriginalCloudServicesInformation: any;
    public OriginalWhichCountryLocation: any;
    public isSecSupChange: boolean = false;
    public hasKeySupplierClicked: boolean =false;
    public secondaryDocOwnerValue: boolean = true;
    public parentDocumentName = {
        value: '',
        oldValue: '',
    };
    constructor(private factory:Factory,
        private HPEService: HPEStaticService,
        private vrService: ViewRecordStaticService,
        private message: MessageService,
        private routeInfo: ActivatedRoute) { }
    ngOnInit() {
        this.obtainSSDS();
        this.searchButtonFlag = true;
        this.service = this.factory.getService();
        this.isParentPop = false;
        //this.isSearchScndSupllier = true;
        this.parentDorpdownValues = {
            getKeyCategorys: [],
            getTypes: [],
            getCountrys: [],
            getStatus: []
        };
        this.recordFormData = this.inputsData.formFields
        console.log("00000000000",this.recordFormData);
        if(this.recordFormData.OnlyNotify.value == ""){
            this.recordFormData.OnlyNotify.value = false
        }
        console.log("OnlyNotifyValue",this.recordFormData);
        // BUG 130233
        if (this.recordFormData.ParentOrSupportingDocument.value == RecordType.Supporting ) {
          this.recordFormData.ParentDocument.isShow = true;
          //this.recordFormData.KeySupplier.value = "No";
        } else {
            this.recordFormData.ParentDocument.isShow = false;
        }
        if (this.recordFormData.ParentOrSupportingDocument.value == RecordType.Parent) {
            this.recordFormData.AgreementStartDate.IsRequired = true;
            this.recordFormData.AgreementEndDate.IsRequired = true;
            this.recordFormData.RenewalNotice.IsRequired = true;
            this.recordFormData.MarketIntelligence.IsRequired = true;
            this.recordFormData.InfoSec.IsRequired = true;
            this.recordFormData.GDPR.IsRequired = true;
            this.recordFormData.USDExceeded.IsRequired = true;
            this.recordFormData.SupplierCodeOfConduct.IsRequired = true;
            this.recordFormData.KeySupplier.IsRequired = true;
            this.recordFormData.LegalDepartmentContact.IsRequired = true;
        } else { 
            this.recordFormData.RenewalType.IsRequired = false;
            this.recordFormData.AgreementStartDate.IsRequired = false;
            this.recordFormData.AgreementEndDate.IsRequired = false;
            this.recordFormData.RenewalNotice.IsRequired = false;
            this.recordFormData.MarketIntelligence.IsRequired = false;
            this.recordFormData.InfoSec.IsRequired = false;
            this.recordFormData.GDPR.IsRequired = false;
            this.recordFormData.USDExceeded.IsRequired = false;
            this.recordFormData.SupplierCodeOfConduct.IsRequired = false;
            this.recordFormData.KeySupplier.IsRequired = false;
            this.recordFormData.LegalDepartmentContact.IsRequired = false;
        }
        this.recordFormData.StakeholderOwner.IsRequired = true;
        this.OriginalRecordType=this.recordFormData.ParentOrSupportingDocument.value;
        this.OriginalMarketIntelligence=this.recordFormData.MarketIntelligence.value;
        this.OriginalInfoSec=this.recordFormData.InfoSec.value;
        this.OriginalGDPR=this.recordFormData.GDPR.value;
        this.OriginalUSDExceeded=this.recordFormData.USDExceeded.value;
        this.OriginalSupplierCodeOfConduct=this.recordFormData.SupplierCodeOfConduct.value;
        this.OriginalAgreementSpentType=this.recordFormData.AgreementSpendType.value;
        this.OriginalLegacyTool=this.recordFormData.LegacyTool.value;
        this.OriginalCloudServicesInformation = this.recordFormData.CloudServicesInformation.value;
        this.OriginalWhichCountryLocation = this.recordFormData.WhichCountryLocation.value;
        this.recordDorpdownData = this.inputsData.dropDownValues;
        this.message.getsearchButtonMsg().subscribe((result) => {
            if (result.searchButtonFlag !== undefined) {
                this.searchButtonFlag = result.searchButtonFlag;
            }
        });
               this.message.getDocToRadioErr().subscribe(result => {
            if (result.hasOwnProperty('FileListlength')) {
                this.FileListlength = result.FileListlength;
            }
        });
        this.message.getParentDocument().subscribe(result => {
            if (result !== undefined) {
                this.recordFormData.ParentDocument.value = result;
                const parentNumber = this.recordFormData.ParentDocument.value;

                let searchParams = 'format=json&q=number:"' + parentNumber + '"';
                const properties = ['number', 'AgreementName'];
                let searchProperties: string = 'properties=' + properties.join('%2C');
                this.service.GetRecordList(searchParams, searchProperties).then(res => {
                    res.Results.forEach(item => {
                        let agreementName = item.Fields.AgreementName.Value;
                        if (item.RecordNumber.Value === parentNumber) {
                            this.parentDocumentName.value = BasicInfoComponent.getParentDocumentText(parentNumber, agreementName);
                        }
                    });
                });
            }
        });
        this.message.getParentDocumentAbled().subscribe(result => {
            if (result.hasOwnProperty('ParentDocumentAbled')) {
                this.ParentDocumentAbled = result.ParentDocumentAbled;
            }
        });
        if(this.routeInfo.snapshot.queryParams.recordUri!=""
            && this.routeInfo.snapshot.queryParams.recordUri!=undefined
            && this.routeInfo.snapshot.queryParams.recordUri!=null){
                this.ParentDocumentAbled=false;
            }
        if(this.routeInfo.snapshot.queryParams.uri!=""
            && this.routeInfo.snapshot.queryParams.uri!=undefined
            && this.routeInfo.snapshot.queryParams.uri!=null){
                this.RecordUri=this.routeInfo.snapshot.queryParams.uri;
            }
        // init keySupplier for supplier search
        this.initKeySupplier();

        // get selected secondary suppliers when page init
        this.message.getSecSupFlag().subscribe(result => {
            if(result.hasOwnProperty('reApprove') && result.reApprove == 'backup'){
                this.isSecSupChange = false;
                var secList = [];
                this.secondarySupplierDataSource.forEach(sec => {
                    if(sec.oldhave && !sec.newhave){} else{
                        let second ={
                            SupplierName: sec.SupplierName,
                            SupplierID: sec.SupplierID,
                            CompanyCode: sec.CompanyCode,
                            SupplierUri: sec.SupplierUri,
                            PrimaryCountry: sec.PrimaryCountry,
                            IsSelected: false,
                            oldhave: false,
                            newhave: false,
                        }
                        secList.push(second);
                    }
                });
                this.secondarySupplierDataSource =  secList;
                this.saveSSDS();
            }
            if(result.hasOwnProperty('clean')){
               this.secondarySupplierDataSource = [];
               this.saveSSDS();
            }
          });

        if (this.secondarySuppliersInputer.reApprove == "backup") {
            this.getSecondarySuppliers_BackupFromRecord(true);
        } else if (this.secondarySuppliersInputer.reApprove == "compare") {
            this.getSecondarySuppliersOandNCompare(true);
        } else {
            this.getSecondarySuppliersFromRecord(true);
        }
        //this.searchRecordChild()

        // PBI 132291 #5 - Display parent Agreement Name By record number.
        if (this.recordFormData.ParentDocument.value || this.recordFormData.ParentDocument.oldValue) {
            const OLD_VALUE_TITLE = 'Previous Value:<br/>';
            const NO_OLD_VALUE = 'No Previous Value';
            let oldParentNumber = this.recordFormData.ParentDocument.oldValue;
            if (oldParentNumber && oldParentNumber !== NO_OLD_VALUE) {
                oldParentNumber = oldParentNumber.split(OLD_VALUE_TITLE)[1];
            }
            const parentNumber = this.recordFormData.ParentDocument.value;

            let searchParams = 'format=json&q=';
            const properties = ['number', 'AgreementName'];
            let searchProperties: string = 'properties=' + properties.join('%2C');

            let parentRecordNumbers = [];
            if (this.recordFormData.ParentDocument.value) {
                parentRecordNumbers.push('number:"' + parentNumber + '"');
            }
            if (this.recordFormData.ParentDocument.oldValue) {
                parentRecordNumbers.push('number:"' + oldParentNumber + '"');
            }
            searchParams += parentRecordNumbers.join(' or ');
            
            if (this.recordFormData.ParentDocument.oldValue === NO_OLD_VALUE) {
                this.parentDocumentName.oldValue = NO_OLD_VALUE;
            }
            this.service.GetRecordList(searchParams, searchProperties).then(res => {
                res.Results.forEach(item => {
                    let agreementName = item.Fields.AgreementName.Value;
                    if (item.RecordNumber.Value === oldParentNumber) {
                        this.parentDocumentName.oldValue = OLD_VALUE_TITLE + BasicInfoComponent.getParentDocumentText(oldParentNumber, agreementName);
                    }
                    if (item.RecordNumber.Value === parentNumber) {
                        this.parentDocumentName.value = BasicInfoComponent.getParentDocumentText(parentNumber, agreementName);
                    }
                });
            });
        }
        this.message.getKeySupplierClickSubject().subscribe(result => {
            if(result.hasOwnProperty('hasKeySupplierClicked') ){
              this.hasKeySupplierClicked =result.hasKeySupplierClicked;
            }
        })
        if(this.isTemplate==true){
            this.recordFormData.ParentDocument.IsRequired = false;
            this.recordFormData.ParentDocument.isRead = false;
            this.searchButtonFlag = true;
            this.ParentDocumentAbled = true;
          }
    }

    checkOne(event: any) {
        this.recordFormData.OnlyNotify.value = !this.recordFormData.OnlyNotify.value;
      }
    ngDoCheck() {
        if (this.recordFormData) {
            // if (this.recordFormData.DocumentOwner && this.recordFormData.SecondaryDocumentOwner && this.recordFormData.DocumentOwner.value === this.recordFormData.SecondaryDocumentOwner.value) {
            //     this.recordFormData.SecondaryDocumentOwner.value = '';
            // }
            if(this.recordFormData.SecondaryDocumentOwner.value == ''){
                this.recordFormData.OnlyNotify.value = false;
                this.secondaryDocOwnerValue = true
            }else{
                this.secondaryDocOwnerValue = false
            }
        }
    }

    searchRecordChild(){
        if(this.RecordUri!="" && this.RecordUri!=undefined && this.RecordUri!=null){
            var data = {uri: this.RecordUri};
            this.service.DocMoveSearchChildsByRecordUri(data).then(res => {
                res.forEach(element => {
                    var res = element;
                    var child = {
                        Uri : res.Uri 
                    }
                    this.childlist.push(child);
                });

                console.log('suc', res);
            }, err => {
                console.log('err', err);
            
            })
        }
    }
    getSecondarySuppliersFromRecord(isInitLoaded: boolean){
            this.secondarySupplierDataSource = [];
            this.obtainSSDS();
            if (this.secondarySuppliersInputer.uri) {
                let param = {
                    "uri": this.secondarySuppliersInputer.uri
                }
                this.service.SecondarySuppliersForRecord(param).then(res => {
                    if (res && res !== "no secondary supplier" && res.length > 0) {
                        res.forEach(sndSupplier => {
                            let ss = {
                                SupplierName: sndSupplier.SupplierName,
                                SupplierID: sndSupplier.SupplierID,
                                CompanyCode: sndSupplier.CompanyCode,
                                SupplierUri: sndSupplier.SupplierUri,
                                PrimaryCountry: sndSupplier.PrimaryCountry,
                                SupplierSource: sndSupplier.SupplierSource,
                                CompanyDescription: sndSupplier.CompanyDescription,
                                IsSelected: false,
                                oldhave: false,
                                newhave: false,
                            };
                            this.secondarySupplierDataSource.push(ss);
                        });
                        this.saveSSDS();
                        this.scndSuplierOutputData.data = this.secondarySupplierDataSource;
                        this.scndSuplierOutputData.isInitDataLoaded = isInitLoaded;
                        this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
                    } else{
                        if(isInitLoaded){
                            this.scndSuplierOutputData.data = [];
                            this.scndSuplierOutputData.isInitDataLoaded = isInitLoaded;
                            this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
                        }
                    }
                    
                }, err => {
                    console.log("secondary suppliers error:", err);
                    if(isInitLoaded){
                        this.scndSuplierOutputData.data = [];
                        this.scndSuplierOutputData.isInitDataLoaded = isInitLoaded;
                        this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
                    }
                });
            }

        }

        getSecondarySuppliers_BackupFromRecord(isInitLoaded: boolean) {
            this.secondarySupplierDataSource = [];
            this.obtainSSDS();
            if (this.secondarySuppliersInputer.uri) {
                let param = {
                    "uri": this.secondarySuppliersInputer.uri
                }
                this.service.SecondarySuppliersForRecordBackup(param).then(res => {
                    if (res && res !== "no secondary supplier" && res.length > 0) {
                        res.forEach(sndSupplier => {
                            let ss = {
                                SupplierName: sndSupplier.SupplierName,
                                SupplierID: sndSupplier.SupplierID,
                                CompanyCode: sndSupplier.CompanyCode,
                                SupplierUri: sndSupplier.SupplierUri,
                                PrimaryCountry: sndSupplier.PrimaryCountry,
                                SupplierSource: sndSupplier.SupplierSource,
                                CompanyDescription: sndSupplier.CompanyDescription,
                                IsSelected: false,
                                oldhave: false,
                                newhave: false,
                            };
                            this.secondarySupplierDataSource.push(ss);
                        });
                        this.saveSSDS();
                        this.scndSuplierOutputData.data = this.secondarySupplierDataSource;
                        this.scndSuplierOutputData.isInitDataLoaded = isInitLoaded;
                        this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
                    } else if(isInitLoaded){
                        this.scndSuplierOutputData.data = [];
                        this.scndSuplierOutputData.isInitDataLoaded = isInitLoaded;
                        this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
                    }
                }, err => {
                    console.log("secondary suppliers error:", err);
                    if(isInitLoaded){
                        this.scndSuplierOutputData.data = [];
                        this.scndSuplierOutputData.isInitDataLoaded = isInitLoaded;
                        this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
                    }
                });
            }
        }

    getSecondarySuppliersOandNCompare(isInitLoaded: boolean) {
        if (this.secondarySuppliersInputer.uri) {
            let param = {
                "uri": this.secondarySuppliersInputer.uri
            }
            this.service.SecondarySupplierOandNCompare(param).then(res => {
                this.secondarySupplierDataSource = [];
               if(res.SecondSupplierList && res.SecondSupplierList !== "no secondary supplier" 
                    && res.SecondSupplierList.length > 0) {
                    res.SecondSupplierList.forEach(val => {
                        let ss = {
                            SupplierName: val.SupplierName,
                            SupplierID: val.SupplierID,
                            CompanyCode: val.CompanyCode,
                            SupplierUri: val.SupplierUri,
                            PrimaryCountry: val.PrimaryCountry,
                            SupplierSource: val.SupplierSource,
                            CompanyDescription: val.CompanyDescription,
                            IsSelected: false,
                            oldhave: val.oldtype,
                            newhave: val.newtype,
                        };
                        this.secondarySupplierDataSource.push(ss);  
                    });
                    this.saveSSDS();
                    this.isSecSupChange = res.isChange;
                    this.scndSuplierOutputData.data = this.secondarySupplierDataSource;
                    this.scndSuplierOutputData.isInitDataLoaded = isInitLoaded;
                    this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
                } else if(isInitLoaded){
                    this.scndSuplierOutputData.data = [];
                    this.scndSuplierOutputData.isInitDataLoaded = isInitLoaded;
                    this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
                }
            }, err => {
                console.log("secondary suppliers compare error:", err);
                if(isInitLoaded){
                    this.scndSuplierOutputData.data = [];
                    this.scndSuplierOutputData.isInitDataLoaded = isInitLoaded;
                    this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
                }
            });
        }
        
    }

    initKeySupplier() {
        if (this.inputsData.formFields.SupplierID) {
            let ssId = this.inputsData.formFields.SupplierID
            this.keySupplier.selectSupplier.SupplierID = ssId;
            // if(ssId == SupplierSearch.NoSapIdRequiered){
            //     this.keySupplier.NoSAPIDrequired = true;
            // }
        }
        if (this.inputsData.formFields.PrimaryCountry) {
            this.keySupplier.selectSupplier.PrimaryCountry = this.inputsData.formFields.PrimaryCountry;
        }
        if (this.inputsData.formFields.SupplierName) {
            this.keySupplier.selectSupplier.SupplierName = this.inputsData.formFields.SupplierName;
        }
        if (this.inputsData.formFields.SupplierUri) {
            this.keySupplier.selectSupplier.SupplierUri = this.inputsData.formFields.SupplierUri;
        }
    }

    setInfo() {
        if(this.recordFormData.PaymentTerms.value == "None of above please specify"){
            this.recordFormData.ReasonForPaymentType.IsRequired = true;
        }
        if (this.recordFormData.ParentOrSupportingDocument.value == RecordType.Supporting) {
          this.recordFormData.ParentDocument.isShow = true;
          //this.recordFormData.StakeholderOwner.IsRequired = true; 
          this.recordFormData.ReasonForPaymentType.IsRequired = false;
          this.recordFormData.LegalDepartmentContact.IsRequired = false;
          //his.recordFormData.KeySupplier.value = "No";
            // this.parentRed = window.localStorage.getItem("parentRed");
            // if(this.parentRed == "true"){
            //     this.recordFormData.ParentDocument.IsRequired = true;
            // }

            if(this.OriginalRecordType == RecordType.Parent && this.inputsData.isSubmissionIngrogress != true && this.isTemplate != true){
                this.recordFormData.ParentDocument.IsRequired=true;
            }
            if(this.hasKeySupplierClicked!=true && this.inputsData.isNewRecordSetup==true){//the user have NOT click key supplier
                this.recordFormData.KeySupplier.value="";
            }
            if(this.templatePage == true){
                this.recordFormData.ParentDocument.IsRequired=false;
            }
            if(this.isTemplate==true){
              this.recordFormData.ParentDocument.IsRequired = false;
              this.recordFormData.ParentDocument.isRead = false;
              this.searchButtonFlag = true;
              this.ParentDocumentAbled = true;
            }
            this.recordFormData.PaymentTerms.IsRequired = true;
            if(this.recordFormData.PaymentTerms.value == "None of above please specify"){
                this.recordFormData.ReasonForPaymentType.IsRequired = true;
            }
        } else {
            this.recordFormData.ParentDocument.isShow = false;
            //this.recordFormData.StakeholderOwner.IsRequired = true;
            this.recordFormData.LegalDepartmentContact.IsRequired = true;
            this.recordFormData.ParentDocument.value = "";
            // PBI 132291 #5 - Display parent Agreement Name By record number.
            this.parentDocumentName.value = "";
            this.inputsData.ParentDocumentUri.uri = "";
            this.recordFormData.ParentDocument.IsRequired=false;
            //Key Supplier to be default as 'No' when type= parent document on the submit form
            if(this.recordFormData.KeySupplier.value == "" ){
                this.recordFormData.KeySupplier.value = "No";
            }
        }
        this.recordFormData.StakeholderOwner.IsRequired = true;
        this.recordFormData.MarketIntelligence.value = this.OriginalMarketIntelligence;
        this.recordFormData.InfoSec.value = this.OriginalInfoSec;
        this.recordFormData.GDPR.value = this.OriginalGDPR;
        this.recordFormData.USDExceeded.value = this.OriginalUSDExceeded;
        this.recordFormData.SupplierCodeOfConduct.value = this.OriginalSupplierCodeOfConduct;
        this.recordFormData.AgreementSpendType.value = this.OriginalAgreementSpentType;
        this.recordFormData.LegacyTool.value = this.OriginalLegacyTool;
        this.recordFormData.CloudServicesInformation.value = this.OriginalCloudServicesInformation;
        this.recordFormData.WhichCountryLocation.value = this.OriginalWhichCountryLocation;
        console.log("OriginalRisk",this.recordFormData);
    }

    isBasic() {
        if (this.isMore == true) {
            this.isMore = false
        } else {
            this.isMore = true
        }
        $(".isBasic").slideToggle();
    }

    getParentSearch(param: any) {
        this.isParentSearchShow = param;
    }
    showParentSearch() {
        this.isParentSearchShow = true;
    }

    setPrepopulateFromParent(item: any) {
        if (item.KeyCategory != "" && this.recordFormData.KeyCategory.value =='') {
            this.inputsData.formFields.KeyCategory.value = item.KeyCategory;
            var getKey = {
                "isCategory": true,
                "keyword": item.KeyCategory,
                "Catword": item.CategoryVal
            }
            this.service.getCategoryMap(getKey).then(res => {
                if (res) {

                    this.inputsData.dropDownValues.CategoryOption = res;
                    this.inputsData.formFields.CategoryVal.IsDisabled = false;
                    this.inputsData.formFields.CategoryVal.value = item.CategoryVal;
                    let isSetCategoryVal = true;
                    this.message.getCategoryLocationSetCategory().subscribe(() => {
                        if (isSetCategoryVal) {
                            this.inputsData.dropDownValues.CategoryOption = res;
                            this.inputsData.formFields.CategoryVal.IsDisabled = false;
                            this.inputsData.formFields.CategoryVal.value = item.CategoryVal;
                            isSetCategoryVal = false;
                        }
                    });

                    if (item.CategoryVal != "") {
                        var getSub = {
                            "isCategory": false,
                            "keyword": item.KeyCategory,
                            "Catword":item.CategoryVal
                        }
                        this.service.getCategoryMap(getSub).then(res => {
                            if (res) {

                                this.inputsData.dropDownValues.SubcategoryOption = res;
                                this.inputsData.formFields.SubCategory.IsDisabled = false;
                                this.inputsData.formFields.SubCategory.value = item.SubCategoryVal;
                                let isSetSubCategoryVal = true;
                                this.message.getCategoryLocationSetSubcategory().subscribe(() => {
                                    if (isSetSubCategoryVal) {
                                        this.inputsData.dropDownValues.SubcategoryOption = res;
                                        this.inputsData.formFields.SubCategory.IsDisabled = false;
                                        this.inputsData.formFields.SubCategory.value = item.SubCategoryVal;
                                        isSetSubCategoryVal = false;
                                    }
                                });

                                if (this.inputsData.formFields.KeyCategory.value == "Technology" && this.recordFormData.CategoryVal.value == "IT Software") {
                                    // this.inputsData.formFields.License.IsDisabled=false;
                                    // this.inputsData.formFields.LicenseType.IsDisabled=false;
                                    this.inputsData.formFields.License.isShow = true;
                                    this.inputsData.formFields.LicenseType.isShow = true;
                                } else {
                                    // this.inputsData.formFields.License.IsDisabled=true;
                                    // this.inputsData.formFields.LicenseType.IsDisabled=true;
                                    this.inputsData.formFields.License.isShow = false;
                                    this.inputsData.formFields.LicenseType.isShow = false;
                                    this.inputsData.formFields.License.value = "";
                                    this.inputsData.formFields.LicenseType.value = "";
                                }
                            }
                        });
                    }
                }
            }); 
            if(this.recordFormData.PrimaryCountry.value !=='' && this.recordFormData.CategoryVal.value !== ''){
                var param = {
                    "Category":  this.recordFormData.CategoryVal.value,
                    "Country":  this.recordFormData.PrimaryCountry.value,
                    "Keycategory":this.recordFormData.KeyCategory.value,
                    "GU": this.recordFormData.PrimaryGU.value,
                }
                this.service.GetDocOwnerByCC(param).then(res => {
                        this.recordFormData.DocumentOwner.value = res;
                        //this.recordFormData.SubCategory.IsDisabled= false;
                });
            }else{
                this.recordFormData.DocumentOwner.value = '';
            }
        }
    }

    //Todo Pre-populate value
    parentSearchOuter(item: any) {
        console.log("getSeletedItem", item);
        this.isParentPop = true;
        this.inputsData.ParentDocumentUri.uri = item.Uri;
        this.recordFormData.ParentDocument.value = item.RecordNumber;
        this.recordFormData.ParentDocument.isValidation = true;
        // BUG 158357
        if (!this.recordFormData.AgreementPaper.value) {
            this.recordFormData.AgreementPaper.value = item.AgreementPaper;
        }
        // PBI 132291 #5 - Display parent Agreement Name.
        this.parentDocumentName.value = BasicInfoComponent.getParentDocumentText(item.RecordNumber, item.RecordTitle);
        // [PBI 640003] Add pre-filling function for supporting records' "Business Contract Owner" field start
        var parentBCO = item.StakeholderOwner;
        var supportingBCO = this.recordFormData.StakeholderOwner.value;
        if(parentBCO !== "") {
            //check parentDCO active
            var data = { name: parentBCO };
            this.service.checkPeopleValidationForBCO(data).then(res => {
                if (res > 0 && supportingBCO == ""){
                    // parent BCO is active
                    // no BCO in new supporting record, pre-fill parent BCO
                    this.recordFormData.StakeholderOwner.value = parentBCO;
                } 

            });
        }
        this.setPrepopulateFromParent(item);
    }

    openSearchSupplier() {
        this.isSearchSupplierShow = true;
    }

    isSupplierShowOrHide(event: any) {
        this.isScndSupllierTipShow = event.isAddScndSupplier;
        this.isSearchSupplierShow = event.isKeySupplierShow;
    }

    openScndSupSearch() {
        this.resetSecondSupplier();
        this.isSearchScndSupllier = true;
        this.closeTip();
    }

    getsearchSupplierData(event: any) {
        this.keySupplier = event;
        if(this.recordFormData.SupplierID.value != event.selectSupplier.SupplierID.value
            || this.recordFormData.SupplierName.value != event.selectSupplier.SupplierName.value
            || this.recordFormData.CompanyCode.value != event.selectSupplier.CompanyCode.value
            || this.recordFormData.SupplierUri.value != event.selectSupplier.SupplierUri.value
            || this.recordFormData.CompanyDescription.value != event.selectSupplier.CompanyDescription.value) {
            this.secondarySupplierDataSource = [];
            this.saveSSDS();
            this.scndSuplierOutputData.data = this.secondarySupplierDataSource;
            this.scndSuplierOutputData.isInitDataLoaded = false;
            this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
        }
        this.recordFormData.SupplierName.value = event.selectSupplier.SupplierName.value;
        this.recordFormData.SupplierID.value = event.selectSupplier.SupplierID.value;
        this.recordFormData.CompanyCode.value = event.selectSupplier.CompanyCode.value;
        this.recordFormData.SupplierUri.value = event.selectSupplier.SupplierUri.value;
        this.recordFormData.CompanyDescription.value = event.selectSupplier.CompanyDescription.value;
        
        if (this.recordFormData.SupplierName.value !== "") {
            this.recordFormData.SupplierName.isValidation = true;
        }
        this.recordFormData.SupplierCountry.value = event.selectSupplier.SupplierCountry.value;
        if (event.selectSupplier.PrimaryCountry.value != "" && this.recordFormData.PrimaryCountry.value == "") {
            this.recordFormData.PrimaryCountry.value = event.selectSupplier.PrimaryCountry.value;
            if(this.recordFormData.CategoryVal.value !== ''){
                var param = {
                    "Category": this.recordFormData.CategoryVal.value,
                    "Country": event.selectSupplier.PrimaryCountry.value,
                    "Keycategory":this.recordFormData.KeyCategory.value,
                    "GU": this.recordFormData.PrimaryGU.value,
                }
                this.service.GetDocOwnerByCC(param).then(res => {
                        this.recordFormData.DocumentOwner.value = res;
                });
            }
            this.message.sendPrimaryChange({ hasPrimary: true });
        }
    }

    chagePOrSValue(event: any) {
        this.recordFormData.LegalDepartmentContact.isValidation = true;
        this.vrService.ValidationReset(this.inputsData.submitFormErrMsg.isNoValidation, this.inputsData.submitFormErrMsg.isSaveNoValidation, this.recordFormData);
        var ids = configurationIDs.submitForm.ParSupRequiredIDs;        
        var fieldNames = this.HPEService.getFormdataFieldNames(ids);
        var radioIds = configurationIDs.submitForm.SubmitRadioFieldIDs;
        var radioNames = this.HPEService.getFormdataFieldNames(radioIds);
        radioNames.forEach(res => {
            if(res=="KeySupplier" && this.recordFormData[res].value!=""){
                
            }else{
                this.recordFormData[res].value = "";
            }
        });
        fieldNames.forEach(res => {
            if (res !== "RenewalNotice" && res !== "AgreementStartDate" && res !== "AgreementEndDate") {
                this.recordFormData[res].isValidation = true;
            } else {
                if((res == "RenewalNotice"||res == "AgreementStartDate"||res == "AgreementEndDate")
                &&(this.recordFormData[res].value == ""||this.recordFormData[res].value == null)) {
                    this.recordFormData[res].isValidation = true;
                }
                // if (res == "RenewalNotice" && this.recordFormData[res].value == ""){
                //     this.recordFormData[res].isValidation = true;
                // }
                // if (res == "AgreementStartDate" && this.recordFormData[res].value == "") {
                //     this.recordFormData[res].isValidation = true;
                // }
                // if (res == "AgreementEndDate" && this.recordFormData[res].value == "") {
                //     this.recordFormData[res].isValidation = true;
                // }
            }
        });
        if (event == RecordType.Parent) {
            fieldNames.forEach(res => {
                this.recordFormData[res].IsRequired = true;
            });
        } else {
            if($("app-warning-tips span").text()==FilesUpload.RecordTypeRestrict_selectParentandUploadMultipleFiles) {
                this.message.sendMsg({fileNoErrMsg: ''});
            }
            fieldNames.forEach(res => {
                this.recordFormData[res].IsRequired = false;
            });
        }
        this.setInfo();
        // if(this.isOtherLanguageShow == true && event == RecordType.Parent){
        //     this.recordFormData.OtherLanguage.IsRequired=true;
        //  }
        //  else{
        //     this.recordFormData.OtherLanguage.IsRequired=false;
        //  }
        this.outputRecordTypeData.emit(event);
        this.recordFormData.PredecessorAgreementID.isError = false;
    }

    resetSecondSupplier() {
        let scndSupplierList = [];
        if (this.keySupplier.supplierList && this.keySupplier.supplierList.length > 0) {
            if (this.secondarySupplierDataSource && this.secondarySupplierDataSource.length > 0) {
                this.keySupplier.supplierList.forEach(keySupplier => {
                    let IsSelected = false;
                    this.secondarySupplierDataSource.forEach(slctedScndSupplier => {
                        if (keySupplier.SupplierUri == slctedScndSupplier.SupplierUri) {
                            IsSelected = true;
                        }
                    });
                    if (!IsSelected) {
                        scndSupplierList.push(keySupplier);
                    }
                });
            } else {
                scndSupplierList = this.keySupplier.supplierList;
            }
        }
        this.scndSupplierList = scndSupplierList;
    }

    searchSecondarySupplier() {
        //this.resetSecondSupplier();
        this.scndSupplierList = [];
        this.isSearchScndSupllier = true;

        
    }
    isScndSupplierShowOrHide(e: any) {
        this.isSearchScndSupllier = e;
    }

    changeSelectionSndSuppliers() {
        let tempNum = 0;
        this.secondarySupplierDataSource.forEach(element => {
            if (!element.IsSelected) return false;
            tempNum++;
        });
        if (tempNum == this.secondarySupplierDataSource.length) {
            this.isAllSupplierChecked = true;
        } else {
            this.isAllSupplierChecked = false;
        }
    }

    removeSndSupplier(uri: number, index: number) {
        if (!this.secondarySupplierDataSource) {
            return;
        }
        this.secondarySupplierDataSource.splice(index, 1);
        this.saveSSDS();
        this.scndSuplierOutputData.data = this.secondarySupplierDataSource ? this.secondarySupplierDataSource : [];
        this.scndSuplierOutputData.isInitDataLoaded = false;
        this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
    }

    changeAllSndSuppliers() {
        this.secondarySupplierDataSource.forEach(element => {
            element.IsSelected = this.isAllSupplierChecked;
        });
        this.saveSSDS();
    }

    closeTip() {
        this.isScndSupllierTipShow = false;
    }

    getScndSuppliers(scndSupplierList: any) {
        if (this.secondarySupplierDataSource.length > 0 && scndSupplierList.length > 0) {
            scndSupplierList.forEach(scndSupplier => {
                this.secondarySupplierDataSource.push(scndSupplier);
            });
            this.saveSSDS();
        } else {
            this.secondarySupplierDataSource = scndSupplierList;
            this.saveSSDS();
        }
        this.scndSuplierOutputData.data = this.secondarySupplierDataSource ? this.secondarySupplierDataSource : [];
        this.scndSuplierOutputData.isInitDataLoaded = false;
        this.sndSuppliersOutputer.emit(this.scndSuplierOutputData);
    }

    public static getParentDocumentText (id, name): string {
        return id + '  ' + name;
    }

    secondarySupplierDataSourceLengthGreatThanThree() {
        return this.secondarySupplierDataSource.length > 4;
    }

    private saveSSDS() {
        if(this.isRequire()){
            this.inputsData.SSDS = this.secondarySupplierDataSource;
        }
    }
    private obtainSSDS() {
        if(this.isRequire()){
            this.secondarySupplierDataSource = this.inputsData.SSDS || [];
        }
    }
    private isRequire(): boolean {
        return this.inputsData.isShowTemplate === false && PageData.getUrlByActivatedRoute(this.routeInfo) === "/newrecord";
    }
}

export class SecondarySuppliersOutputData{
    data: any [];
    isInitDataLoaded: boolean;
}
