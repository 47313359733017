import {
  Directive, QueryList, EventEmitter, AfterContentChecked,
   OnDestroy, OnInit, Output, ContentChild, AfterViewInit, ContentChildren, forwardRef
} from '@angular/core';
import { TabelFilterService } from './service/tabel-filter.service';
import { DataItem } from './models';
import { ColFilterDirective } from './col-filter.directive';

/**
 * A lightweight, extensible directive for fancy popover creation.
 */
@Directive({
  selector: '[tabelfilter]',
   exportAs: 'tabel-filter',
   providers: [
     TabelFilterService
   ]
  })
export class TabelFilterDirective implements OnInit, OnDestroy {

  /**
   * 
   */
  @Output()
  public filterItems: EventEmitter<any> = new EventEmitter<any>();

  itemEvent: EventEmitter<any> = new EventEmitter<any>();

  private setTimeoutId: any = null;

  public colFilters = new Map(); //Empty Map

  constructor(
    public _tabelFilterService: TabelFilterService
  ) {
  }

  ngOnInit(): void {

    this.itemEvent.subscribe((filterItem) => {
      if(this.setTimeoutId != null){
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        this.filterItems.emit(this._tabelFilterService.getFilterQueryMap());
          clearTimeout(this.setTimeoutId);
          this.setTimeoutId = null;
      }, 50);
    });

  }

  cleanFilter(event: MouseEvent): void {
    this._tabelFilterService.getFilterQueryMap().clear();
    this.filterItems.emit(this._tabelFilterService.getFilterQueryMap());
    this._tabelFilterService.cleanAllData();
    if(this.colFilters.has("CategoryVal")){
      const categoryVal = <any>this.colFilters.get("CategoryVal");
      categoryVal.categoryShowAction(false);
      categoryVal._instance.removeItems();
    }
    if(this.colFilters.has("PrimaryGU")){
      const primaryGU = <any>this.colFilters.get("PrimaryGU");
      primaryGU.categoryShowAction(false);
      primaryGU._instance.removeItems();
    }
    if(this.colFilters.has("PrimaryRegion")){
      const primaryRegion = <any>this.colFilters.get("PrimaryRegion");
      primaryRegion.categoryShowAction(false);
      primaryRegion._instance.removeItems();
    }
  }

  public hasAllData(): boolean {
    return this._tabelFilterService.getFilterQueryMap().size > 0;
  }

  getFilterString(): string {
    return this._tabelFilterService.getFilterString();
  }

  getFilterObj(): object {
    return this._tabelFilterService.getFilterObj();
  }

  getFilterQueryMap(): Map<any, any> {
    return this._tabelFilterService.getFilterQueryMap();
  }

  hideAllDropDown(){
    const scrollHide = (this._tabelFilterService as any).bsDropdownColl;
    scrollHide.forEach(element =>{
      element.hide()
    })
  }
  pushColFilter(colFilter: ColFilterDirective): void {
    this.colFilters.set(colFilter.property, colFilter);
    this.colFilters.forEach((menber, key) => {
      if((menber.relations as any).includes(colFilter.property)){
        menber._relations.set(colFilter.property, colFilter);
      }
      if((colFilter.relations as any).includes(menber.property)){
        colFilter._relations.set(menber.property, menber);
      }
    });


  }

  ngOnDestroy(): void {
    
  }
}
