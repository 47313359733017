import { Component,EventEmitter,Output } from '@angular/core';
import { Factory } from '../../../../../app.factory';
import { environment } from 'environments/environment';
@Component({
    selector: 'RequestInfo',
    templateUrl: './RequestInfo.component.html',
    styleUrls: ['./RequestInfo.component.css'],
    providers: [Factory],
    inputs: ["request"],
})
export class RequestInfoComponent {
    public isMore: boolean = true;
    public service: any; 
    public searchRes: any;
    public searchDataQ: string;
    public searchDataProperties: string;
    public recordUri: any;
    private _service: any;
    public successFiles: any = [];
    public request: any;
    public Requestor:any;
    public ExtensionDocs:any;
    public requestInfo:  boolean;
    public downurlprefix:any;
    public downurlsuffix:any;
    constructor(private factory:Factory,) { }
    ngOnInit() {
        this.Requestor = this.request.Requestor;
        this.ExtensionDocs = this.request.ExtensionDocs;
        this.requestInfo = this.request.requestInfo;
        this.downurlprefix=environment.HPApiBase + 'Record/' ;
        this.downurlsuffix= '/File/document';
    }

    isRequest() {
        if(this.isMore == true){
            this.isMore = false
        }else{
            this.isMore = true
        }
        $(".isRequest").slideToggle();
    }

    downloadFile(doc){
       
       
        const request = new XMLHttpRequest();
        request.open("GET", environment.HPApiBase + 'Record/' + doc.Uri + '/File/document', true);
        request.setRequestHeader("Cache-Control", "no-cache");
        request.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem('Token'));
        
        request.responseType = 'blob';
        request.onload = (e) => {
            this.afterGetFile(e, doc);
        }
        request.send( null ); // post-data
    }

    afterGetFile(e, doc) {
        const blob = e.target.response;
        if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
			return navigator.msSaveBlob(blob, doc.FileName);
		}
        const mimeType = "application/octet-stream";
        // handle non-Blob()+non-URL browsers:
        if(typeof blob === "string" || blob.constructor===toString ){
            try{
                return this.saver( "data:" +  mimeType   + ";base64,"  +  window.btoa(blob), false, doc);
            }catch(y){
                return this.saver( "data:" +  mimeType   + "," + encodeURIComponent(blob), false, doc);
            }
        }
        if(window.URL) {
            this.saver(window.URL.createObjectURL(blob), true, doc);
        } else {
        // Blob but not URL support:
        const reader=new FileReader();
        reader.onload = (e) => {
            this.saver(reader.result, false, doc);
        };
        reader.readAsDataURL(blob);
    }
}
    saver(url, winMode, doc){
        const defaultMime = "application/octet-stream";
        // handle non-a[download] safari as best we can:
        if(/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)) {
            if(/^data:/.test(url))	url="data:"+url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
            if(!window.open(url)){ // popup blocked, offer direct download:
                if(confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")){
 location.href=url; 
}
            }
            return true;
        }

        if(!winMode && /^data:/.test(url)){ // force a mime that will download:
            url="data:"+url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
        }

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("download", doc.FileName);
        a.href = url;
        a.click();
        setTimeout(function(){
 document.body.removeChild(a); 
}, 333);
    }
}
