<section>
    <div class="mask" *ngIf="isScndSupllierTipShow"></div>
    <supplierNameSearch *ngIf="isSearchSupplierShow" [recordCountry]="recordDorpdownData.getCountrys"
        (searchDataFn)="getsearchSupplierData($event)" (isShowOrHideFn)="isSupplierShowOrHide($event)">
    </supplierNameSearch>

    <app-parent-search *ngIf="isParentSearchShow" [isParentSearchShow]="isParentSearchShow"
        [dorpdownValues]="recordDorpdownData" [SupplierName]="recordFormData.SupplierName.value"
        [AgreementID]="recordFormData.AgreementID.value" (parentSearchOuter)="parentSearchOuter($event)"
        (parentSearchShowOuter)="getParentSearch($event)">
    </app-parent-search>

    <secondarySupplierSearch *ngIf="isSearchScndSupllier" [recordCountry]="recordDorpdownData.getCountrys"
        [keySupplier]="keySupplier" [secondarySupplierDataSource]="secondarySupplierDataSource"
        [supplierList]="scndSupplierList" (isShowOrHideFn)="isScndSupplierShowOrHide($event)"
        (selectedScndSup)="getScndSuppliers($event)"></secondarySupplierSearch>

    <div class="secondarySupplierContain" *ngIf="isScndSupllierTipShow">
        <div class="titlBox">
            <p class="team_title">Secondary Supplier(s)</p>
            <i (click)="closeTip()" class="mdui-icon material-icons close_icon">clear</i>
        </div>
        <div class="rr_content">
            <p class="contentTitle_Font">Do you have secondary Accenture company codes?</p>
        </div>
        <div class="btn_padd">
            <button type="submit" class="btn-primary fr" (click)="openScndSupSearch()">
                <i class="mdui-icon material-icons btn_icon">&#xe876;</i>
                <span class="btn_txt">Yes</span>
            </button>
            <button type="submit" class="btn-secondary fr" (click)="closeTip()">
                <i class="mdui-icon material-icons btn_icon">&#xe14c;</i>
                <span class="btn_txt">No</span>
            </button>
        </div>
    </div>

    <div class="rele">
        <div class="up_title one_up" (click)="isBasic()">
            <i *ngIf="isMore" class="mdui-icon material-icons expend_icon">expand_more</i>
            <i *ngIf="!isMore" class="mdui-icon material-icons expend_icon">chevron_right</i> Supplier Basic Info
        </div>
        <div class="tab_content isBasic">
            <div class="tab_line">
                <div class="row">
                    <div class="col-6 col-lg-3" [ngClass]="{'display-flex': !recordFormData.SupplierName.isRead}">
                        <app-input *ngIf="recordFormData.SupplierName.isRead || !searchButtonFlag" style="width: 100%;"
                            [inputData]="recordFormData.SupplierName"></app-input>
                        <div *ngIf="!recordFormData.SupplierName.isRead && searchButtonFlag"
                            style="width: calc(100% - 30px);">
                            <app-input [inputData]="recordFormData.SupplierName"></app-input>
                        </div>
                        <div *ngIf="!recordFormData.SupplierName.isRead && searchButtonFlag" class="searchIcon"
                            style="width: 30px;">
                            <i (click)="openSearchSupplier()" class="mdui-icon material-icons search_btn">search</i>
                        </div>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-input [inputData]="recordFormData.SupplierID"></app-input>
                        <input type="hidden" [(ngModel)]="recordFormData.SupplierUri" hidden>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-input [inputData]="recordFormData.CompanyCode"></app-input>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-input [inputData]="recordFormData.CompanyDescription"></app-input>
                    </div>
                    
                </div>
                <div class="row mard_10">
                    <div class="col" *ngIf="recordFormData.CompanyCode.value !== '' ">
                        <div class="secondary_supplier">
                            <div style="width:100%;display:flex;padding-bottom:9px;"
                                *ngIf="!secondarySuppliersInputer.isRead || secondarySupplierDataSource.length">
                                <div>
                                    <span class="field_title" [ngClass]="{'titleupdate': isSecSupChange}">Secondary
                                        Supplier(s)</span>
                                </div>
                                <div *ngIf="!secondarySuppliersInputer.isRead" class="wid_8" style="padding-left:5px;">
                                    <i (click)="searchSecondarySupplier()"
                                        class="mdui-icon material-icons search_btn second_search_btn">search</i>
                                </div>
                            </div>
                            <div id="tableContiner" style="width:100%"
                                *ngIf="secondarySupplierDataSource.length">
                                <table class="table" *ngIf="secondarySupplierDataSource.length">
                                    <thead>
                                        <tr class="t_header">
                                            <!-- <th *ngIf="!secondarySuppliersInputer.isRead" style="width: 5%;vertical-align: middle;" > -->
                                            <!-- <input type="checkbox" id="ss_chk" [(ngModel)]="isAllSupplierChecked" (ngModelChange)="changeAllSndSuppliers()"/> -->
                                            <!-- </th> -->
                                            <th
                                                 [ngClass]="{'w247':secondarySuppliersInputer.isRead, 'w251':!secondarySuppliersInputer.isRead}">
                                                Supplier Name</th>
                                            <th
                                                [ngClass]="{'w2565':secondarySuppliersInputer.isRead, 'w26':!secondarySuppliersInputer.isRead}">
                                                <ng-container [ngSwitch]="recordFormData?.SupplierID?.value">
                                                    <ng-container *ngSwitchCase="'Not required'">Country/Location
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'Awaiting SAP ID'">Country/Location
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>Supplier SAP ID</ng-container>
                                                </ng-container>
                                            </th>
                                            <th
                                                [ngClass]="{'secondReadTh': secondarySuppliersInputer.isRead, 'secondReadScrollTh': !secondarySuppliersInputer.isRead}">
                                                Company Code</th>
                                                <th>Company Description</th>
                                            <th *ngIf="!secondarySuppliersInputer.isRead"
                                                [ngClass]="{'w241':!secondarySupplierDataSourceLengthGreatThanThree(), 'w23':secondarySupplierDataSourceLengthGreatThanThree()}">
                                                Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="tab_row"
                                            *ngFor="let scndSupplier of secondarySupplierDataSource;let i=index"
                                            [ngClass]="{'ssoldvalueClass': scndSupplier.oldhave && !scndSupplier.newhave}">
                                            <!-- <td *ngIf="!secondarySuppliersInputer.isRead" style="width: 5%;vertical-align: middle;"> -->
                                            <!-- <input type="checkbox" [(ngModel)]="scndSupplier.IsSelected" (ngModelChange)="changeSelectionSndSuppliers()"/> -->
                                            <!-- </td> -->
                                            <td [ngClass]="{'w247':!secondarySupplierDataSourceLengthGreatThanThree(), 'w251':secondarySupplierDataSourceLengthGreatThanThree()}"
                                                [tooltip]="scndSupplier.SupplierName"><span
                                                    [ngClass]="{'txtLimit': !scndSupplier.oldhave && scndSupplier.newhave}">{{scndSupplier.SupplierName}}</span>
                                            </td>
                                            <td
                                                [ngClass]="{'w2565':!secondarySupplierDataSourceLengthGreatThanThree(), 'w26':secondarySupplierDataSourceLengthGreatThanThree()}">
                                                <span
                                                    [ngClass]="{'txtLimit': !scndSupplier.oldhave && scndSupplier.newhave}">
                                                    <ng-container [ngSwitch]="recordFormData?.SupplierID?.value">
                                                        <ng-container *ngSwitchCase="'Not required'">
                                                            {{scndSupplier.PrimaryCountry}}</ng-container>
                                                        <ng-container *ngSwitchCase="'Awaiting SAP ID'">
                                                            {{scndSupplier.PrimaryCountry}}</ng-container>
                                                        <ng-container *ngSwitchDefault>{{scndSupplier.SupplierID}}
                                                        </ng-container>
                                                    </ng-container>
                                                </span>
                                            </td>
                                            <td><span
                                                    [ngClass]="{'txtLimit': !scndSupplier.oldhave && scndSupplier.newhave}">{{scndSupplier.CompanyCode}}</span>
                                            </td>
                                            <td><span
                                                [ngClass]="{'txtLimit': !scndSupplier.oldhave && scndSupplier.newhave}">{{scndSupplier.CompanyDescription}}</span>
                                        </td>
                                            <td [ngClass]="{'w247':!secondarySupplierDataSourceLengthGreatThanThree(), 'w23':secondarySupplierDataSourceLengthGreatThanThree()}"
                                                *ngIf="!secondarySuppliersInputer.isRead">
                                                <div class="delete_btn"
                                                    (click)="removeSndSupplier(scndSupplier.Uri, i)">
                                                    <i class="mdui-icon material-icons btn_remove">&#xe872;</i>
                                                    <span class="spn_remove">Remove</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mard_2">
                    <div class="col-6 col-lg-3">
                        <app-radio [inputData]="recordFormData.ParentOrSupportingDocument" (change)="setInfo()"
                            (outputData)="chagePOrSValue($event)"></app-radio>
                    </div>
                    <div class="col-6 col-lg-6" *ngIf="recordFormData.ParentDocument.isShow"
                        [ngClass]="{'display-flex': !recordFormData.ParentDocument.isRead}">
                        <app-input [displayValue]="parentDocumentName"
                            *ngIf="recordFormData.ParentDocument.isRead || !searchButtonFlag"
                            [inputData]="recordFormData.ParentDocument" style="width: 100%;"></app-input>
                        <div *ngIf="!recordFormData.ParentDocument.isRead && searchButtonFlag"
                            style="width: calc(100% - 30px);">
                            <app-input [displayValue]="parentDocumentName" [inputData]="recordFormData.ParentDocument">
                            </app-input>
                        </div>
                        <div *ngIf="!recordFormData.ParentDocument.isRead && searchButtonFlag && ParentDocumentAbled"
                            class="searchIcon" style="width: 30px;">
                            <i (click)="showParentSearch()" class="mdui-icon material-icons search_btn">search</i>
                        </div>
                    </div>
                    <div *ngIf="recordFormData.ParentDocument.isShow" class="col-0 col-lg-3">
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-peoplepickerForBCO [inputData]="recordFormData.StakeholderOwner"></app-peoplepickerForBCO>
                    </div>
                    <div class="col-6 col-lg-3 father">
                        <!-- <app-input [inputData]="recordFormData.LegalDepartmentContact"></app-input> -->
                        <app-peoplepicker [inputData]="recordFormData.LegalDepartmentContact"></app-peoplepicker>
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-input [inputData]="recordFormData.DocumentOwner"></app-input>
                    </div>
                    <div class="col-6 col-lg-3 rel">
                        <!-- <document-owner-picker [inputData]="recordFormData.SecondaryDocumentOwner" [MarkAsSecDocOwner] = true></document-owner-picker> -->
                        <app-secdocument-owner-picker [inputData]="recordFormData.SecondaryDocumentOwner" [MarkAsSecDocOwner] = true></app-secdocument-owner-picker>
                        <!-- Only Notify start -->
                        <div *ngIf="!recordFormData.OnlyNotify.isRead">
                            <div *ngIf="!recordFormData.ParentDocument.isShow" class="notifyBox">
                                <input id="notNotify" [disabled]="secondaryDocOwnerValue" (ngModelChange)="recordFormData.OnlyNotify.value = checkOne($event)" [(ngModel)]="recordFormData.OnlyNotify.value" class="multi_checkbox fl cur" type="checkbox"/>
                                <label for="notNotify" class="fontStyle fl cur">Only Notify</label>
                                <i class="mdui-icon material-icons nosapInfo" [tooltip]="recordFormData.OnlyNotify.information">info</i>
                            </div>
                            <div *ngIf="recordFormData.ParentDocument.isShow" class="notifyBoxS">
                                <input id="notNotify" [disabled]="secondaryDocOwnerValue" (ngModelChange)="recordFormData.OnlyNotify.value = checkOne($event)" [(ngModel)]="recordFormData.OnlyNotify.value" class="multi_checkbox fl cur" type="checkbox"/>
                                <label for="notNotify" class="fontStyle fl cur">Only Notify</label>
                                <i class="mdui-icon material-icons nosapInfo" [tooltip]="recordFormData.OnlyNotify.information">info</i>
                            </div>
                        </div>
                        <div *ngIf="recordFormData.OnlyNotify.isRead">
                            <div *ngIf="!recordFormData.ParentDocument.isShow" class="notifyBox">
                                <input [(ngModel)]="recordFormData.OnlyNotify.value" class="multi_checkbox fl" type="checkbox" disabled/>
                                <label class="fontStyle fl">Only Notify</label>
                            </div>
                            <div *ngIf="recordFormData.ParentDocument.isShow" class="notifyBoxSR">
                                <input [(ngModel)]="recordFormData.OnlyNotify.value" class="multi_checkbox fl" type="checkbox" disabled/>
                                <label class="fontStyle fl">Only Notify</label>
                            </div>
                        </div>
                        <!-- Only Notify end -->
                    </div>
                    <div class="col-6 col-lg-3">
                        <app-clientpicker [inputData]="recordFormData.PrimaryClient"></app-clientpicker>
                    </div>
                    <!-- <div class="col-6 col-lg-3">
                        <app-input [inputData]="recordFormData.ClientName"></app-input>
                    </div> -->
                    <div class="col-6 col-lg-3">
                        <app-input [inputData]="recordFormData.ClientName"></app-input>
                    </div>
                    <div class="col-12 col-lg-9" style="padding: 0;">
                        <div class="row" style="margin: 0;">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <hr class="com_hr"> -->
</section>







