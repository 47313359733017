<ul *ngIf="showComponent" class="pg-pos">
  <li style="margin-right:50px;">
    <label for="goto_page">Go to</label>
    <input id="goto_page" type="tel" #box [(ngModel)]="manualPage" class="go-to-input" (keyup)="onKey(box.value)" (blur)="updateManualPage($event)" (keyup.enter)="onEnter(box)">
    <span>/{{totalPage}}</span>
  </li>
  <li>
      <nav class="pagination">
        <ul>
          <li class="page-item" [class.disabled]="!showPrevious" (click)="goFirstPage()">
            <a class="page-link"  aria-label="First" mdbWavesEffect>
              <svg width="6px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="arrow"><path fill="#0041f0" d="M20.2 247.5L167 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L85.3 256l118.5 119.7c4.7 4.7 4.7 12.3 0 17L184 412.5c-4.7 4.7-12.3 4.7-17 0l-146.8-148c-4.7-4.7-4.7-12.3 0-17zm160 17l146.8 148c4.7 4.7 12.3 4.7 17 0l19.8-19.8c4.7-4.7 4.7-12.3 0-17L245.3 256l118.5-119.7c4.7-4.7 4.7-12.3 0-17L344 99.5c-4.7-4.7-12.3-4.7-17 0l-146.8 148c-4.7 4.7-4.7 12.3 0 17z" class=""></path></svg>
            </a>
          </li>
          <li class="page-item" [class.disabled]="!showPrevious" (click)="goPreviousPage()">
            <a class="page-link" aria-label="Previous" mdbWavesEffect>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="arrow single"><path fill="currentColor" d="M4.2 247.5L151 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L69.3 256l118.5 119.7c4.7 4.7 4.7 12.3 0 17L168 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 264.5c-4.7-4.7-4.7-12.3 0-17z" class=""></path></svg>
            </a>
          </li>
          <li *ngFor="let iPage of countPage"
            [class.active]="iPage === manualPage"
            (click)="onSelectPage(iPage)"
            class="page-item">
            <a class="page-link" mdbWavesEffect><p class="page-index" [class.active]="iPage === manualPage">{{iPage}}</p></a>
          </li>
          <li class="page-item" [class.disabled]="!showNext" (click)="goNextPage()">
            <a class="page-link" aria-label="Next" mdbWavesEffect>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="arrow single"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z" class=""></path></svg>
            </a>
          </li>
          <li class="page-item" [class.disabled]="!showNext" (click)="goLastPage()">
            <a class="page-link"  aria-label="Last" mdbWavesEffect>
              <svg width="6px" height="16px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="arrow"><path fill="currentColor" d="M363.8 264.5L217 412.5c-4.7 4.7-12.3 4.7-17 0l-19.8-19.8c-4.7-4.7-4.7-12.3 0-17L298.7 256 180.2 136.3c-4.7-4.7-4.7-12.3 0-17L200 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17zm-160-17L57 99.5c-4.7-4.7-12.3-4.7-17 0l-19.8 19.8c-4.7 4.7-4.7 12.3 0 17L138.7 256 20.2 375.7c-4.7 4.7-4.7 12.3 0 17L40 412.5c4.7 4.7 12.3 4.7 17 0l146.8-148c4.7-4.7 4.7-12.3 0-17z" class=""></path></svg>
            </a>
          </li>
        </ul>
      </nav>
  </li>
</ul>
<div style="display:none">
  <mat-paginator #_paginatorNew
                [length]="_length"
                [pageSize]="_pageSize"
                [pageSizeOptions]="_pageSizeOptions"
                [showFirstLastButtons]="true">
  </mat-paginator>
</div>