import { Injectable } from "@angular/core";

import {SimpleSearchComponent} from '../component/simple-search.component';
import {SimpleDropdownComponent} from '../component/simple-dropdown.component';
import {MuiltSearchComponent} from '../component/muilt-search.component';
import {DateScopeComponent} from '../component/date-scope.component';

import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { CallBack, DataItem } from '../models';



@Injectable()
export class TabelFilterService {

    private containerTypeMap = new Map(); //Empty Map

    private cleanDataCallBackMap = new Map(); //Empty Map

    private filterQueryMap: any = new Map();

    private bsDropdownColl: Array<any> = [];

    constructor() {
        this.initContainerType();
    }

    private initContainerType(){

        this.containerTypeMap
        .set('simple-search', SimpleSearchComponent)
        .set('simple-dropdown', SimpleDropdownComponent)
        .set('date-scope', DateScopeComponent)
        .set('muilt-search', MuiltSearchComponent);
    }

    getContainerType(type: string): any {
       return this.containerTypeMap.get(type);
    }

    public singleShowFilter(bsDropdown: BsDropdownDirective) {
        const _bsDropdown = this.bsDropdownColl.pop();
        if(_bsDropdown != null && _bsDropdown !== bsDropdown){
            _bsDropdown.hide();
        }
        this.bsDropdownColl.push(bsDropdown);
    }

    public uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public rigistCleanDataCallBack(cleanDataInfo: CallBack){
        this.cleanDataCallBackMap.set(cleanDataInfo.key, cleanDataInfo);
    }

   public cleanAllData(): void {
        this.cleanDataCallBackMap.forEach((cleanDataInfo: CallBack, key, map) => {
            const callback = cleanDataInfo.callback;
            callback();
        });
    }

    public hasAllData(): boolean {
        let hasAllData = false;
        this.cleanDataCallBackMap.forEach((cleanDataInfo: CallBack, key, map) => {
            const hasCondition = cleanDataInfo.hasCondition;
            if(hasCondition()){
                hasAllData = true;
            }
        });
        return hasAllData;
    }

    public addQuery(data: DataItem){
        this.filterQueryMap.set(data.alias, data);
    }

    public removeQuery(data: DataItem){
        this.filterQueryMap.delete(data.alias);
    }

    public getFilterString(): string {
        let filterString = '';
        this.filterQueryMap.forEach((member: DataItem, key, map) => {
          filterString += ` ${member.convertString()}`;
        });
        return filterString;
    }

    public getFilterObj(): object {
        let filterObj = {};
        this.filterQueryMap.forEach((member: DataItem, key, map) => {
            filterObj[key] = member.value;
        });
        return filterObj;
    }

    public getFilterQueryMap(): Map<any, any> {
        return this.filterQueryMap;
    }
}